<template>
    <div>
        <!-- 弹出消失框 -->
        <van-notice-bar
            class="alarm-area"
            ref="notice"
            v-if="alarmTag"
            left-icon="volume-o"
            background="#e25b4d"
            color="#c1ffe3"
            :text="scrollText"
        />
        <!-- 告警中列表框 -->
        <div class="tag-box" v-if="!alarmTag" @click="boxStatus = true">
            <van-badge :content="alarmListContent.length" :dot="alarmListContent.length == 0">
                <van-icon name="bell" color="#1dccd4" size="30" />
            </van-badge>
        </div>
        
        <van-popup v-model="boxStatus" position="top" round>
            <div class="top-show-area">
                <div v-if="alarmListContent.length > 0">
                    <van-notice-bar 
                        class="notice-item"
                        v-for="(item,index) in alarmListContent" :key="item.devName+Math.random().toString(8)"
                        left-icon="volume-o"
                        background="#e25b4d"
                        color="#c1ffe3"
                        :text="item.text"
                        mode="closeable"
                        @close="handleItemRead(item,index)"
                    />
                </div>
                <div v-else class="hint">
                    暂无告警数据
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
const mqtt = require('mqtt');
import MQTT_OPTIONS from '@/config/mqtt.js'
export default {
    data(){
        return{
            projectCode:null,
            client: null,
            alarmTag:false,
            scrollText:null,
            timer:null,
            boxStatus:false,
            alarmListContent:[]
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.mqttMsg();
    },
    beforeDestroy(){
        this.client ? this.client.end() : void 0;
    },
    methods:{
        mqttMsg(){
            this.client  = mqtt.connect('ws://ahdcloud.com:20502/mqtt',MQTT_OPTIONS);
            // 告警频道
            const topicAlarm = `/sudp/sync/jcw/${this.projectCode}/+/alarm/get`;

            this.client.on('connect', (e) => {
                console.log(e, "MQTT连接成功！！！");
                this.client.subscribe(topicAlarm, {}, (error) => {  // qos 为通道
                    if (!error) {
                        console.log('告警频道订阅成功',topicAlarm)
                    } else {
                        console.log('告警频道订阅失败')
                    }
                })
            })
            
            // 接收消息处理
            this.client.on('message', (topic, message) => {
                const msg = JSON.parse(message.toString());
                console.log(topic,msg)
                this.handleMsg(msg);
            })
            // 断开发起重连
            this.client.on('reconnect', (error) => {
                console.log('正在重连:', error)
            })
            // 链接异常处理
            this.client.on('error', (error) => {
                console.log('连接失败:', error)
            })
        },

        // 处理告警信息
        handleMsg(msg){
            this.timer ? clearTimeout(this.timer) : void 0;
            this.scrollText = `设备${msg.devName}发生告警，告警内容：${msg.content}，告警级别：${msg.level}，触发时间：${msg.createTime}`;
            this.alarmTag = true;
            // 重置滚动效果
            this.$nextTick(()=>{
                this.$refs.notice.reset();
            })

            this.alarmListContent.push({
                ...msg,text:this.scrollText
            });

            this.timer = setTimeout(()=>{
                this.alarmTag = false;
            },12000)
        },
        // 操作告警已读
        handleItemRead(item,index){
            this.$api.JCW.handleAlarm({
                devCode:item.devCode,
                productCode:'jcw',
                type:item.alarmType,
            }).then( d => {
                this.$toast('操作成功');
                this.alarmListContent.splice(index,1);
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.alarm-area{
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    box-sizing: border-box;
}
.top-show-area{
    padding: 15px;
    height: 100%;
    overflow-y: auto;
    .notice-item{
        margin-bottom: 5px;
    }
    .hint{
        color: #717171;
        font-size: .8rem;
    }
}
.tag-box{
    position: fixed;
    top: 3%;
    left: 4%;
    z-index: 9;
}
</style>